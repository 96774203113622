.dfl_custome_container_staticpage {
  width: 100%;
  max-width: 1200px;
}
.def_wedding_text_box.banner_cotntent h2 {
  font-family: 'Nunito Sans', sans-serif !important;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 10px;
}
.eggemtlinkss {
  padding: 0 4px;
  color: #7a98a0 !important;
}
.eng_faq_title h2 {
  margin-bottom: 0.5rem;
}
.dfld_loose_diamond_Section {
  background-color: #e2e9e9;
  background-image: url(../../Assets//images/landing-page-img/trans-bg.png);
  width: 100%;
  height: 500px;
  padding: 40px 0;
  position: relative;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}
.dfld_loose_diamond_Section::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background-color: #2a292900;
}
.dfl_diamond_gif {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 13%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.dfld_loose_text_box {
  color: #000;
}
.dfld_loose_text_box h2 {
  font-size: 24px;
  color: #000;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 35px;
  margin-bottom: 15px;
  font-family: 'Tinos', serif !important;
}
.dfld_loose_text_box h1 {
  font-size: 51px;
  margin-top: 18px;
  margin-bottom: 14px;
  font-family: ' Nunito Sans' !important;
}
.dfld_loose_text_box h3 {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: ' Nunito Sans' !important;
}
.dfld_loose_text_box p {
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.4px;
  font-family: 'Nunito Sans' !important;
  margin-bottom: 10px;
}
.dfld_loose_text_box .btn-engage {
  text-align: center;
  border-radius: 25px;
  margin-right: 18px;
  font-size: 13px;
  width: 190px;
  display: block;
  height: 42px;
  line-height: 40px;
  border: 1px solid transparent;
  background: #333;
  color: #fff;
  margin: 0 auto;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
}
.dfld_loose_text_box a.btn-engage:hover {
  background: #fff;
  color: #000;
  border: 1px solid #000;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
}
.dfld_loose_text_box .btn-diamond {
  background: #fff;
  color: #333;
  border-radius: 0;
  text-align: center;
  font-size: 13px;
  width: 190px;
  display: block;
  height: 42px;
  line-height: 40px;
  border: 1px solid transparent;
  margin: 0 auto;
  font-weight: 600;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
  text-transform: uppercase;
}
.dfl_d_font_waight_800 {
  font-weight: 900 !important;
  font-size: 18px;
}
.dfl_d_font_waight_900 {
  font-weight: 900 !important;
  font-size: 28px;
}
.dfl_d_btn_display_flexxx {
  display: flex;
}
.dfl_el_mobile_img {
  /* height: 400px; */
  width: 100%;
  object-fit: cover;
  /* background-color: #617f87; */
}
.dfl_eng_box {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}
.dfld_loose_text_box a.btn-diamond:hover {
  background: #7a98a0;
  color: #fff;
  border: 1px solid #7a98a0;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
}
.dfl_e_engament_ring_section a.btn-diamond:hover {
  background: #7a98a0 !important;
  color: #fff !important;
  border: 2px solid #7a98a0 !important;
}
.dfld_loose_text_box .eng-btn .btn-wrapper {
  padding-top: 30px;
  display: flex;
  width: 97%;
  margin: 0 auto;
}
/* button design */
.dfl_earth_lab_button_section {
  position: relative;
  /* padding: 80px 0; */
  /* overflow-x: hidden; */
  background-image: url('../images//landing-page-img/start-with-lab-earth-banner.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  height: 700px;
  display: flex;
  align-items: center;
  vertical-align: middle;
}
/* .dfl_bg_banner_earthlab{
   background-image: url('../images//landing-page-img/start-with-lab-earth-banner.jpg');
   width: 100%;
   height: 450px;
   background-repeat: no-repeat;
   background-size: cover;
   background-position: 100% 100%;
} */
.dfld_landing_hading_with_border.dfl_start_with_heading_large_font h2 {
  font-size: 60px;
  line-height: 40px;
  font-family: 'Tinos', serif !important;
  text-transform: uppercase;
  color: #fff;
  text-align: left;
}
.dfl_earth_lab_button_section .btn-engage {
  font-size: 18px;
  width: 100%;
  height: 58px;
  margin: 0 auto;
  text-transform: uppercase;
  line-height: 58px;
  background-color: transparent;
  color: #fff;
  border: 1.5px solid #000;
  background-color: #000;
  transition: all 0.4s ease-in-out;
  padding: 0 0px;
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 20px;
  font-weight: 600;
}
.dfl_earth_lab_button_section .btn-engage.btn_dimnd {
  font-size: 14px;
  width: 45%;
  height: 44px;
  margin: 0 auto;
  text-transform: uppercase;
  line-height: 41px;
  background-color: transparent;
  color: #000;
  background-color: #fff;
  border: 1.5px solid #fff;
  transition: all 0.4s ease-in-out;
  padding: 0 30px;
  margin-bottom: 10px;
  margin-left: 0;
  font-weight: 600;
}
.dfl_earth_lab_button_section .btn-engage:hover {
  background-color: #fff;
  color: #000;
  border: 1.5px solid #000;
  transition: all 0.4s ease-in-out;
}
.dfl_earth_lab_button_section .btn-engage.btn_dimnd:hover {
  background-color: #000000f1;
  color: #fff;
  border: 1.5px solid #000;
  transition: all 0.4s ease-in-out;
}
.dfl_buttons_text_box_outer {
  /* position: absolute; */
  /* top: 33%; */
  /* right: 0; */
  width: 700px;
}
.dfl_buttons_text_box_outer p {
  font-size: 18px;
  width: 86%;
  margin: 15px 0 20px;
  font-family: 'Nunito Sans' !important;
  text-align: left;
  color: #fff;
}
.dfl_earth_lab_button_section .btn-diamond {
  font-size: 16px;
  width: 60%;
  height: 48px;
  margin: 0 auto;
  text-transform: uppercase;
  line-height: 47px;
}
.dfld_earth_mind img {
  margin-bottom: 30px;
}
.dfl_padiding_left_zero {
  padding-left: 0;
}
.dfl_padiding_right_zero {
  padding-right: 0;
}
/* Shop daimonds by shap style */
.dfld_landing_hading_with_border {
  text-align: center;
}
.dfld_landing_hading_with_border h2,
.dfld_landing_hading_with_border h1 {
  font-size: 30px;
  line-height: 35px;
  font-family: 'Tinos', serif !important;
}

.dfld_black_border_img {
  width: 170px;
  height: auto;
  margin: 5px auto;
}
.dfld_explore_diamond_by_shap_section {
  padding: 40px 0;
}
.dfld_img_shap_box_inner img {
  width: 120px;
  height: 120px;
  margin: 0 auto 20px;
}
.dfld_img_shap_box_inner {
  text-align: center;
  padding: 20px;
  cursor: pointer;
}
.dfld_explore_diamond_shap_slider .slick-prev,
.dfld_explore_diamond_shap_slider .slick-next {
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.dfld_img_shap_box_inner h4 {
  color: #7a98a0;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 400;
  font-family: 'Nunito Sans', sans-serif !important;
}
.dfld_img_shap_box_inner p {
  font-size: 15px;
  padding: 5px 10px;
  line-height: 21px;
  color: #777;
  width: 90%;
  margin: 0 auto;
}
.dfld_shap_slider {
  margin-top: 30px;
}

/* Own Ring style */
.dfld_own_engagment_ring_section {
  background: #e2e9e9;
  padding: 50px 0;
}
.dfld_own_engagment_ring_section h2 {
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  line-height: 35px;
  font-family: 'Tinos', serif !important;
}
.dfld_own_engagment_ring_section span.conf-spn {
  font-weight: 600;
  color: #454548;
}
.dfld_own_engagment_ring_section .both-pr {
  font-size: 15px;
  line-height: 28px;
  color: #1b1b1b;
  text-align: center;
}
.dfld_own_engagment_ring_section .eng-btn {
  padding-top: 20px;
}
.eng-btn ul {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.eng-btn ul li {
  display: inline-block;
  padding: 5px 5px;
  font-size: 21px;
  font-weight: bolder;
  color: #7a98a0;
  font-family: 'Nunito Sans';
}
.eng-btn li img {
  width: 58px;
}
.eng-btn ul li a img {
  transition: all 0.4s;
}
.eng-btn {
  padding-top: 20px;
}
.btn-engage:hover {
  background: #fff;
  color: #000;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  border: 2px solid #000;
}
.btn-engage {
  text-align: center;
  margin-right: 18px;
  font-size: 13px;
  width: 190px;
  display: block;
  height: 42px;
  line-height: 40px;
  border: 2px solid #000;
  text-transform: uppercase;
  margin: 0 auto;
  font-weight: 600;
  background: #333;
  color: #fff;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.btn-diamond {
  background: #fff;
  color: #333;
  border-radius: 0;
  text-align: center;
  font-size: 13px;
  width: 190px;
  display: block;
  height: 42px;
  line-height: 40px;
  border: 1px solid transparent;
  margin: 0 auto;
  font-weight: 600;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.btn-diamond:hover {
  background: #7a98a0;
  color: #fff;
  border: 1px solid #7a98a0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.box-design .btn-diamond {
  background: #fff;
  color: #000;
  border: 2px solid #000;
  line-height: 36px;
}
.box-design .btn-diamond:hover {
  background: #000;
  color: #fff;
  border: 2px solid #000;
}
.eng-btn {
  padding-top: 20px;
}
.eng-box2 .btn-wrapper.btn-wrapper2 {
  margin-top: 20px !important;
}
.title-box4 {
  margin-bottom: 20px;
}
.eng-box2 .btn-wrapper.btn-wrapper2 {
  margin-top: 20px !important;
}
.eng-btn ul li a img:hover {
  transform: scale(1.1);
  transition: all 0.4s;
}
.pd-less {
  padding: 0px;
}
.d-secv1 {
  background-color: #eee;
}
.both-box-hd {
  text-align: center;
  font-family: 'Cinzel', serif;
  padding: 14px 0px;
}
.both-pr {
  font-size: 16px;
  line-height: 28px;
  color: #1b1b1b;
  text-align: center;
}
/* diamond eduction style */
.d-secv1 a {
  font-size: 16px;
  text-align: center;
  display: block;
  padding: 7px;
  width: 148px;
  margin: 0 auto;
  margin-top: 0px;
  border-radius: 25px;
  text-decoration: underline;
  padding-top: 0px;
  color: #000;
}
.d-secv1 {
  height: 310px;
  position: relative;
  padding: 16px 35px;
  /* background-image: url(../../Assets//images/landing-page-img/bg1.png); */
  background-size: 200px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.d-secv1::before {
  position: absolute;
  left: 0;
  top: 30%;
  content: 'Grading Report';
  font-size: 60px;
  font-weight: 500;
  color: #d9d9d9;
  z-index: -2;
  right: 0;
  margin: 0 auto;
  text-align: center;
}
.dfld_diamond_Education_Section {
  padding: 60px 0 50px;
}
.d-secv2 {
  background-color: #f7f7ff;
}
.d-secv2 {
  height: 310px;
  position: relative;
  padding: 16px 35px;
  background-image: url(../../Assets//images/landing-page-img/bg2.png);
  background-size: 270px;
  background-position: center;
  background-repeat: no-repeat;
}
.both-box-hd {
  text-align: center;
  font-family: 'Cinzel', serif !important;
  padding: 14px 0px;
}
.both-pr {
  font-size: 16px;
  line-height: 28px;
  color: #1b1b1b;
  text-align: center;
  font-family: 'Nunito Sans';
}
.d-secv2 a {
  font-size: 16px;
  text-align: center;
  display: block;
  padding: 7px;
  width: 184px;
  margin: 0 auto;
  margin-top: 0px;
  border-radius: 25px;
  text-decoration: underline;
  padding-top: 0px;
  color: #000;
}
span.conf-spn {
  font-weight: 600;
  color: #454548;
}
.dfld_looss_img {
  position: relative !important;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}
.box-design .btn-engage {
  background: #000;
  border: 2px solid transparent;
}
/* ring page style */
.dfl_e_engament_ring_section {
  background: #e2e9e9;
  background-image: url('../images//landing-page-img/engagment-static-page-img/eng-banner-desktop.png');
  height: 620px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: bottom left;
}

.title32 {
  font-size: 32px;
  letter-spacing: 0.8px;
  font-family: 'Tinos', serif !important;
  text-transform: uppercase;
  text-align: center;
}
.both-pr {
  font-size: 16px;
  line-height: 28px;
  color: #1b1b1b;
  text-align: center;
}
.box-design .eng-btn li img {
  width: 80px !important;
}
.both-pr {
  font-size: 16px;
  line-height: 28px;
  color: #1b1b1b;
  text-align: center;
}
.box-design .both-pr {
  width: 87%;
  margin: 0 auto;
}
.dfl_eng_Shop_ring_by_style_section {
  padding: 50px 0;
}
.dfl_eng_Shop_ring_by_style_section .border-title h2 {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
  font-family: 'Tinos', serif !important;
  font-size: 24px;
  text-transform: uppercase;
}
.title-box2 {
  margin-bottom: 25px;
}
.border-title img {
  margin-top: -15px;
}
.dfld_img_shap_box_inner.dfl_eng_ring_img img {
  width: 200px;
  height: 200px;
  cursor: pointer;
}
.dfld_img_shap_box_inner.dfl_eng_ring_img p {
  font-size: 13px;
  padding: 5px 10px;
  line-height: 21px;
  color: #777;
  width: 100% !important;
  margin: 0 auto;
}
.title32 {
  font-size: 32px;
  letter-spacing: 0.8px;
}
.title30 {
  font-size: 24px;
}
.both-pr {
  font-size: 16px;
  line-height: 28px;
  color: #1b1b1b;
  text-align: center;
}
.btn-wrapper {
  padding-top: 30px;
  display: flex;
}
.dfld_loose_text_box .btn-wrapper .btn-engage {
  background: #000;
  color: #fff;
  text-align: center;
  margin-right: 18px;
  font-size: 18px;
  display: block;
  height: 58px;
  line-height: 58px;
  border: 1px solid #0000;
  font-weight: 600;
  font-family: 'Nunito Sans' !important;
  border-radius: 0;
  width: 100% !important;
}
.dfld_loose_text_box a.btn-engage:hover {
  background: #fff;
  color: #000;
  border: 1px solid #000;
  transition: all 0.4s;
}
.btn-wrapper .btn-engage {
  background: #000;
  color: #fff;
  text-align: center;
  border-radius: 0;
  margin-right: 18px;
  font-size: 18px;
  display: block;
  height: 58px;
  line-height: 58px;
  border: 1px solid #0000;
  font-weight: 600;
  font-family: 'Nunito Sans' !important;
  width: 100%;
}
a.btn-engage:hover {
  background: #fff;
  color: #000;
  border: 1px solid #000;
  transition: all 0.4s;
}
.box-design .btn-wrapper {
  padding-top: 23px;
  display: flex;
  margin-bottom: -20px;
  width: 96%;
  margin: 0 auto;
}
.dfl_eng_exclusive_collection_section .btn-wrapper .btn-engage {
  background: #333;
  color: #fff;
}
.eng-inner-banner-text .btn-engage {
  margin-left: auto;
  margin-right: auto;
}
.eng-inner-banner-text h2.title30 {
  margin-top: 13px;
  margin-bottom: 10px;
  font-family: 'Nunito', 'Sans' !important;
}
.eng-inner-banner-text h2.title30 {
  margin-top: 13px;
  margin-bottom: 10px;
  font-family: 'Tinos', serif !important;
}
.eng-inner-banner-text {
  padding: 50px;
  padding-bottom: 10px;
}
.zoom-image:hover .adv-thumb-link > img {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.adv-thumb-link > img {
  display: block;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
}
/* Metal conent style */
.metal_content_left {
  padding-right: 5px;
  padding-left: 0;
  margin-bottom: 10px;
  cursor: pointer;
}
.banner-adv {
  display: block !important;
}
.adv-thumb-link {
  display: block;
  overflow: hidden;
  position: relative;
}
.rose_bg {
  background-color: #f1e3e2;
  height: 354px;
  position: relative;
}
.metal_inner_cotnent_top {
  position: absolute;
  left: 65px;
  top: 40px;
}
.rose_bg h2,
.white_bg h2,
.platinum_bg h2,
.yellow_bg h2 {
  font-size: 35px;
  margin: 20px 0 10px;
  letter-spacing: 12px !important;
  font-weight: 600 !important;
  font-family: 'Nunito Sans', sans-serif !important;
  text-transform: uppercase !important;
}
.rose_bg h2,
.rose_bg p {
  color: #a3192e;
  font-family: 'Nunito Sans', sans-serif !important;
  text-transform: uppercase !important;
}
.rose_bg p,
.white_bg p,
.platinum_bg p,
.yellow_bg p {
  letter-spacing: 4px !important;
  font-weight: 800 !important;
  font-family: 'Nunito Sans', sans-serif !important;
  text-transform: uppercase !important;
}
.metal_content p {
  text-transform: uppercase;
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight: 800 !important;
  letter-spacing: 4px !important;
  font-size: 12px !important;
  padding-right: 13px;
}
.metal_content h2 {
  text-transform: uppercase !important;
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight: 600 !important;
  letter-spacing: 12px !important;
  font-size: 35px !important;
}
.metal_inner_cotnent_top1 {
  position: absolute;
  right: 65px;
  top: 40px;
}
.white_bg h2,
.white_bg p {
  color: #656565;
  text-align: right;
}
.white_bg {
  background-color: #f2f2f2 !important;
  height: 354px;
  position: relative;
}
.metal_content_right {
  padding-left: 5px;
  padding-right: 0;
  margin-bottom: 10px;
  cursor: pointer;
}
.platinum_bg {
  background-color: #cecdcb;
  height: 343px;
  position: relative;
}
.metal_inner_cotnent_bottom {
  position: absolute;
  left: 65px;
  bottom: 40px;
}
.platinum_bg h2,
.platinum_bg p {
  color: #1a1a1a;
}
.yellow_bg h2,
.yellow_bg p {
  color: #ca7a20;
  text-align: right;
}
.metal_inner_cotnent_bottom1 {
  position: absolute;
  right: 65px;
  bottom: 40px;
}
.yellow_bg {
  background-color: #efe8d5;
  height: 343px;
  position: relative;
}
.top_heading_metal h3 {
  text-align: center;
  padding: 20px 0;
  color: #656565;
  text-transform: uppercase;
  font-family: 'Nunito Sans' !important;
  font-weight: 300 !important;
  font-size: 20px;
  letter-spacing: 8px;
}
.dfl_eng_metal_type_section {
  padding: 40px 0 30px;
}

/* Wedding Page Style */
.dfl_wedding_banner_section {
  background-image: url(../../Assets/images/landing-page-img/wedding-bannerr.png);
  width: 100%;
  height: 630px;
  background-repeat: no-repeat;
  background-position: top 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  background-color: #F9ECE8;
}

.banner_cotntent h1 {
  font-family: 'Nunito Sans', sans-serif !important;
  text-align: center;
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 20px;
  line-height: 40px;
}
.banner_cotntent p {
  text-align: center;
  padding: 0 80px;
  line-height: 22px;
  font-weight: 600;
  font-family: 'Nunito Sans', sans-serif !important;
  font-size: 16px;
}
.banner_cotntent a {
  display: block;
  border: 1px solid #000;
  text-transform: uppercase;
  color: #000;
  border-radius: 0;
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight: 600;
  margin: 20px auto;
  width: 60%;
  text-align: center;
  transition: all 0.4s ease-in-out;
  font-size: 18px;
  height: 58px;
  line-height: 58px;
  background-color: #000;
  color: #fff;
}
.banner_cotntent a:hover {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000;
}
.dfl_bgimg_ring_style {
  background-image: url(../images/landing-page-img/wedding-shop_by_style.jpg);
  width: 100%;
  height: 480px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: cover;
  padding: 20px 35px;
}
.top_heading_metal h2 {
  text-align: center;
  padding: 30px 0 50px;
  color: #656565;
  text-transform: uppercase;
  font-family: 'Nunito Sans';
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 8px;
  /* margin-bottom: 20px; */
}
.dfl_ring_style_slider .dfld_img_shap_box_inner h4 {
  color: #7a98a0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Nunito Sans', sans-serif !important;
}
.dfl_ring_style_slider .dfld_img_shap_box_inner p {
  font-size: 13px;
  padding: 5px 0px;
  line-height: 21px;
  color: #777;
  width: 100%;
  margin: 0 auto;
}
.dfl_ring_style_slider .dfld_img_shap_box_inner img {
  width: 150px;
  height: 150px;
  margin: 0 auto 10px;
}
.dfl_wedding_eternity_ring_section {
  background-image: url(../images/landing-page-img/wedding-eternity_rings_banner.jpg);
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
  padding: 10px 10px;
  background-position: 100% 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}
.eternity_ring_content h2 {
  font-size: 35px;
  font-weight: 800;
  letter-spacing: 5px;
  color: #000;
  padding: 0 0 10px;
  margin: 0;
}
.eternity_ring_content a {
  display: block;
  border: 1px solid #000;
  background-color: #000;
  text-transform: uppercase;
  color: #fff;
  border-radius: 0;
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight: 600;
  margin: 20px auto;
  width: 60%;
  text-align: center;
  transition: all 0.4s ease-in-out;
  height: 58px;
  line-height: 58px;
  font-size: 18px;
}

.eternity_ring_content p {
  text-align: center;
  line-height: 22px;
  font-weight: 600;
  font-family: 'Nunito Sans', sans-serif;
  padding: 0 50px;
  font-size: 15px;
}
.eternity_ring_content a:hover {
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
  transition: all 0.4s ease-in-out;
}
.dfl_wedding_static_page_section .top_heading_metal h2 {
  text-align: center;
  padding: 0px 0 30px;
  color: #656565;
  text-transform: uppercase;
  font-family: 'Nunito Sans';
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 8px;
}
/* fine jewelery style */
.dfl_fine_jwelery_banner {
  background-image: url(../images/landing-page-img/fine-jewelery-img/fine-banner.jpg);
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 522px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}
.banner_cotntent h1 {
  font-family: 'Nunito Sans', sans-serif;
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 10px;
}
.banner_cotntent h1 span {
  font-size: 80px;
  letter-spacing: 20px;
  font-weight: 400;
  line-height: 95px;
}
.banner_cotntent p {
  text-align: center;
  padding: 0 80px;
  line-height: 22px;
  font-weight: 600;
  font-family: 'Nunito Sans', sans-serif !important;
  font-size: 16px;
}

.banner_cotntent a:hover {
  transition: all 0.4s ease-in-out;
}
.dfl_fine_collection_Section {
  padding: 70px 0 0;
}
.content_left h2 {
  text-align: right;
  text-transform: uppercase;
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight: 800;
  letter-spacing: 1px !important;
  padding-right: 15px;
  font-size: 20px;
}
.content_left p {
  text-align: end;
  line-height: 22px;
  padding-right: 25px;
  font-size: 14px;
  margin: 20px 0 35px;
}
.content_left a {
  float: right;
  text-transform: uppercase;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 800;
  letter-spacing: 3px;
  padding-right: 25px;
}
.ring_bg h2,
.ring_bg a,
.ring_bg a:hover {
  color: #06315e;
}
.banner-adv {
  display: block !important;
}
.ring_bg {
  background-color: #e8f3f7;
  height: 343px;
  position: relative;
}
.ring_left_inner img {
  display: block;
  margin-top: 120px;
}
.adv-thumb-link > img {
  display: block;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
}
.zoom-image:hover .adv-thumb-link > img {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
}
.content_left_inner {
  padding: 80px 5px 40px 0;
}
.cool-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  transition: width 0.3s;
}
.cool-link:hover::after {
  width: 100%;
}
.ring_bg a.cool-link::after {
  background: #06315e;
}
.ring_left_inner img {
  display: block;
  margin-top: 120px;
  width: 100%;
  height: auto;
}
.content_right {
  padding-right: 0;
  padding-left: 5px;
  margin-bottom: 10px;
}
.banner-adv {
  display: block !important;
}
.earring_bg {
  background-color: #ecdbd6;
  height: 343px;
  position: relative;
}
.content_right_inner {
  padding: 80px 0 40px 5px;
}
.earring_bg h2,
.earring_bg a,
.earring_bg a:hover {
  color: #a41a2f;
}
.content_right h2 {
  text-align: left;
  text-transform: uppercase;
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight: 800;
  letter-spacing: 1px;
  padding-left: 15px;
  font-size: 20px;
}
.content_right p {
  text-align: left;
  line-height: 22px;
  padding-left: 15px;
  font-size: 14px;
  margin: 20px 0 35px;
}
.content_right a {
  text-align: left;
  text-transform: uppercase;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 800;
  padding-left: 15px;
  letter-spacing: 3px;
}
.earring_bg a.cool-link::after {
  background: #a41a2f;
}
.no-padding {
  padding: 0;
}
.adv-thumb-link {
  display: block;
  overflow: hidden;
  position: relative;
}
.adv-thumb-link > img {
  display: block;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
}
.content_right {
  padding-right: 0;
  padding-left: 5px !important;
  margin-bottom: 10px !important;
}
.content_right a {
  text-align: left;
  text-transform: uppercase;
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight: 800;
  padding-left: 15px;
  letter-spacing: 3px;
}
.cool-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  transition: width 0.3s;
}
.cool-link {
  display: inline-block;
  color: #000;
  text-decoration: none;
}

.content_left {
  padding-right: 5px !important;
  padding-left: 0 !important;
  margin-bottom: 10px;
}
.necklaces_bg img,
.pendants_bg img {
  height: 343px;
}
.necklaces_bg {
  background-color: #edebf5;
  height: 343px;
  position: relative;
}
.adv-thumb-link {
  display: block;
  overflow: hidden;
  position: relative;
}
.banner-adv {
  display: block !important;
}
.bracelets_bg {
  background-color: #f3ebe1;
  height: 343px;
  position: relative;
}
.necklaces_bg a.cool-link::after {
  background: #5d145e;
}
.bracelets_bg a.cool-link::after {
  background: #ca7920;
}
/* .content_left h2 {
    text-align: right;
    text-transform: uppercase;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 800;
    letter-spacing: 8px;
    padding-right: 15px;
} */
.necklaces_bg h2,
.necklaces_bg a,
.necklaces_bg a:hover {
  color: #5d145e;
}
.bracelets_bg h2,
.bracelets_bg a,
.bracelets_bg a:hover {
  color: #ca7920;
}
.pendants_bg {
  background-color: #d9e6e8;
  height: 343px;
  position: relative;
}
.pendants_bg h2,
.pendants_bg a,
.pendants_bg a:hover {
  color: #034e61;
}
.content_left p {
  text-align: end;
  line-height: 22px;
  padding-right: 25px;
  font-size: 14px;
  margin: 20px 0 35px;
}
.pendants_bg a.cool-link::after {
  background: #034e61;
}
.guid_bg h2,
.guid_bg a,
.guid_bg a:hover {
  color: #145e17;
}
.guid_bg {
  background-color: #ecf5eb;
  height: 343px;
  position: relative;
}
.guid_bg a.cool-link::after {
  background: #145e17;
}
.ringsize_left_inner img {
  margin-top: 80px;
}
.content_right p {
  text-align: left;
  line-height: 22px;
  padding-left: 15px;
  font-size: 14px;
  margin: 20px 0 35px;
}
.dfl_fine_jewelery_section .top_heading_metal h2 {
  padding: 10px 0 20px;
}
.dfl_finr_coll_bracelet_img img {
  width: 250px;
  margin-top: 50px;
}

/********************** Responsive style *************************/
@media (min-width: 320px) and (max-width: 359px) {
  .d-secv1::before {
    top: 42%;
    font-size: 35px;
  }
  .both-pr {
    font-size: 15px;
    line-height: 22px;
  }
  .dfl_fine_collection_Section img {
    width: 100px !important;
  }
  .content_left h2 {
    font-size: 13px !important;
    letter-spacing: 0px !important;
  }
  .content_right h2 {
    font-size: 13px !important;
    letter-spacing: 0 !important;
  }
  .rose_bg,
  .white_bg,
  .platinum_bg,
  .yellow_bg {
    height: 87px !important;
  }
  .banner_cotntent h1 {
    font-size: 15px;
    line-height: 25px;
  }
}
@media (min-width: 360px) and (max-width: 767px) {
  .both-pr {
    font-size: 16px;
    line-height: 22px;
  }
}
@media (min-width: 360px) and (max-width: 374px) {
  .rose_bg,
  .white_bg,
  .platinum_bg,
  .yellow_bg {
    height: 99px !important;
  }
}

@media (min-width: 375px) and (max-width: 389px) {
  .rose_bg,
  .white_bg,
  .platinum_bg,
  .yellow_bg {
    height: 105px !important;
  }
}
@media (min-width: 390px) and (max-width: 413px) {
  .rose_bg,
  .white_bg,
  .platinum_bg,
  .yellow_bg {
    height: 110px !important;
  }
}
@media (min-width: 414px) and (max-width: 427px) {
  .rose_bg,
  .white_bg,
  .platinum_bg,
  .yellow_bg {
    height: 117px !important;
  }
}
@media (min-width: 428px) and (max-width: 560px) {
  .rose_bg,
  .white_bg,
  .platinum_bg,
  .yellow_bg {
    height: 118px !important;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .bracelets_bg {
    height: 193px;
  }
  .title32 {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 0;
    margin-top: 30px;
  }
  .dfl_d_btn_display_flexxx {
    display: block;
  }
  .dfl_earth_lab_button_section {
    padding: 20px 0;
    background-image: url('../images//landing-page-img/el-dimond-mobile-img.png');
    width: 100%;
    height: 400px;
    background-position: top;
  }
  .dfl_buttons_text_box_outer {
    width: 300px;
    text-align: center;
    margin: 0 auto;
    margin-top: 90px;
  }
  .dfld_landing_hading_with_border h2,
  .dfld_landing_hading_with_border h1 {
    text-align: center !important;
  }
  .dfld_landing_hading_with_border.dfl_start_with_heading_large_font h2 {
    font-size: 35px;
    line-height: 30px;
  }
  .dfl_earth_lab_button_section .btn-engage {
    font-size: 16px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .dfl_earth_lab_button_section .btn-engage.btn_dimnd {
    font-size: 13px;
    width: 100%;
    height: 42px;
    line-height: 40px;
    margin: 0 auto;
  }
  .dfl_buttons_text_box_outer p {
    font-size: 16px;
    width: 100%;
    text-align: center;
  }
  .content_right {
    padding-left: 0 !important;
  }
  .dfld_earth_mind img {
    margin-bottom: 25px;
  }
  .content_left {
    padding-right: 0 !important;
  }
  .dfl_wedding_eternity_ring_section {
    position: relative;
    background-position: 87% 100%;
  }
  .dfl_fine_jwelery_banner {
    position: relative;
  }
  .dfl_fine_jwelery_banner::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: '';
    background-color: #1918184d;
    width: 100%;
    height: 100%;
  }
  .dfl_bgimg_ring_style {
    background-image: unset;
    background-color: #fbf6f2;
    height: 410px;
  }
  .dfl_wedding_eternity_ring_section {
    height: unset !important;
    padding: 0 15px !important;
    position: relative;
    z-index: 1;
  }
  .eternity_ring_content h2 {
    font-size: 25px;
    margin-bottom: 20px;
    padding: 30px 0 10px 0;
    margin: 0;
  }

  .dfl_wedding_eternity_ring_section {
    position: relative;
    margin-top: 20px;
  }
  .dfl_wedding_static_page_section .top_heading_metal h2 {
    padding: 0px 0 0px;
    margin-bottom: 10px;
  }
  .dfl_wedding_eternity_ring_section ::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: '';
    background-color: #f9ece821;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .eternity_ring_content p {
    padding: 0 10px;
    font-size: 14px;
  }
  .metal_content_right {
    padding-left: 3px;
    margin-bottom: 5px;
  }
  .metal_content_left {
    padding-right: 2px;
    margin-bottom: 5px;
  }
  .dfl_finr_coll_bracelet_img {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
  }
  .dfl_fine_jwelery_banner {
    height: 362px;
    background-position: 40% 100%;
  }
  .banner_cotntent h1 span {
    font-size: 30px;
    line-height: 15px;
  }
  .banner_cotntent p {
    padding: 0;
    font-size: 14px;
  }
  /* .banner_cotntent a {
	font-size: 14px;
} */
  .eternity_ring_content a {
    width: 100%;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
  }
  .banner_cotntent a {
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    width: 100%;
  }
  .banner_cotntent h1 {
    font-size: 20px;
    line-height: 30px;
  }
  .dfl_fine_collection_Section {
    padding: 10px 0 0;
  }
  .ring_left_inner img {
    display: block;
    margin-top: 10% !important;
    width: 140px;
    object-fit: contain;
    height: 180px;
  }
  .dfl_finr_coll_bracelet_img img {
    margin-top: 0px;
  }
  .content_left_inner {
    padding: 40px 0px 0px 0;
  }
  .content_left h2 {
    font-size: 14px;
  }
  .content_left p {
    font-size: 14px;
    margin: 10px 0 15px;
  }
  .pendants_bg {
    height: 192px;
  }
  .ring_bg {
    height: 192px;
  }
  .necklaces_bg {
    height: 192px;
  }
  .guid_bg {
    height: 192px;
  }
  .content_right_inner {
    padding: 40px 0 0px 0px;
  }
  .content_right h2 {
    font-size: 14px;
  }
  .content_left a {
    letter-spacing: 2px;
    padding-right: 25px;
    font-size: 10px !important;
  }
  .content_right a {
    padding-left: 15px;
    letter-spacing: 2px;
    font-size: 10px !important;
  }
  .content_right p {
    margin: 10px 0 25px;
  }
  .ringsize_left_inner img {
    margin-top: 0px;
  }
  .earring_bg {
    height: 200px;
  }
  .dfl_eng_Shop_ring_by_style_section {
    padding: 30px 0;
  }
  .dfl_eng_metal_type_section {
    padding: 20px 0 20px;
  }
  .dfl_e_engament_ring_section .btn-diamond {
    width: 100%;
  }
  .eng-inner-banner-text {
    padding: 10px;
    padding-bottom: 10px;
  }
  .border-title img {
    margin-top: -15px;
    width: 60px;
  }
  .dfl_eng_Shop_ring_by_style_section .border-title h2 {
    font-size: 18px;
  }
  .rose_bg h2,
  .white_bg h2,
  .platinum_bg h2,
  .yellow_bg h2 {
    font-size: 11px;
    letter-spacing: 4px !important;
  }
  .rose_bg p,
  .white_bg p,
  .platinum_bg p,
  .yellow_bg p {
    letter-spacing: 1px !important;
    font-size: 8px;
  }
  .rose_bg,
  .white_bg,
  .platinum_bg,
  .yellow_bg {
    height: 94px;
  }
  .metal_inner_cotnent_top {
    left: 15px;
    top: 0px;
  }
  .metal_inner_cotnent_top1 {
    right: 15px;
    top: 0px;
  }
  .metal_inner_cotnent_bottom {
    left: 15px;
    bottom: 0px;
  }
  .metal_inner_cotnent_bottom1 {
    right: 15px;
    bottom: 0px;
  }
  .dfl_diamond_gif {
    width: 100px;
    height: 100px;
    top: 9%;
  }
  .dfld_loose_text_box h2 {
    font-size: 25px;
    margin-top: 20px;
  }
  .dfld_loose_text_box h1 {
    font-size: 35px;
    margin-top: 8px;
    margin-bottom: 5px;
  }
  .dfld_loose_diamond_Section {
    height: 710px;
    padding: 20px 0;
  }
  .dfld_loose_text_box h3 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 30px;
  }
  .dfld_loose_text_box p {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 22px;
  }
  .dfld_own_engagment_ring_section .both-pr {
    line-height: 23px;
  }
  .dfld_loose_text_box .btn-engage {
    margin-bottom: 15px;
    width: 100%;
  }
  .dfld_loose_text_box .btn-diamond {
    width: 100%;
  }
  .btn-wrapper .btn-engage {
    width: 100%;
    height: 48px !important;
    line-height: 48px !important;
    font-size: 16px !important;
  }
  .btn-diamond {
    width: 100%;
  }
  .box-design .eng-btn li img {
    width: 40px !important;
  }
  .box-design .btn-wrapper {
    width: 100%;
    padding-left: 10px;
  }
  .dfld_explore_diamond_shap_slider .slick-prev {
    width: 30px;
    height: 30px;
    left: 5px;
  }
  .dfld_explore_diamond_shap_slider .slick-next {
    width: 30px;
    height: 30px;
    right: 5px;
  }
  .dfld_landing_hading_with_border h2 {
    font-size: 25px;
    line-height: 32px;
  }
  .dfld_black_border_img {
    width: 140px;
  }
  .dfld_explore_diamond_by_shap_section {
    padding: 40px 0 0;
  }
  .dfld_Own_Ring_img {
    text-align: center;
  }
  .dfld_Own_Ring_img img {
    width: 250px !important;
    margin: 0 auto;
  }
  .dfld_own_engagment_ring_section h2 {
    font-size: 25px;
  }
  .btn-engage {
    margin-bottom: 15px;
  }
  .dfld_diamond_Education_Section {
    padding: 0px 0 0px;
  }
  .d-secv1 {
    height: 400px;
  }
  .d-secv2 {
    height: 430px;
  }
  .dfl_fine_collection_Section img {
    width: 130px;
    object-fit: contain;
  }
  .dfl_wedding_banner_section {
    background-image: unset;
  }
  .dfl_wedding_banner_section img {
    margin: 20px auto 20px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .d-secv1::before {
    top: 36%;
    font-size: 45px;
  }
  .def_wedding_text_box.banner_cotntent h1 {
    font-size: 16px !important;
    line-height: 22px;
  }
  .def_wedding_text_box.banner_cotntent h2 {
    font-size: 17px;
    letter-spacing: 3px;
    margin-bottom: 7px;
    line-height: 25px;
  }
  .dfl_wedding_banner_section {
    height: 320px;
    background-position: 43% 100%;
  }
  .dfl_e_engament_ring_section {
    height: 390px;
    background-position: 40% 100%;
  }
  .box-design .both-pr {
    width: 100%;
  }
  .box-design .eng-btn li img {
    width: 50px !important;
  }
  .dfl_earth_lab_button_section {
    height: 280px;
  }
  .dfl_buttons_text_box_outer p {
    font-size: 14px;
    width: 100%;
  }
  .dfld_landing_hading_with_border.dfl_start_with_heading_large_font h2 {
    font-size: 25px;
    line-height: 10px;
  }
  .ringsize_left_inner img {
    margin-top: 0;
  }
  .content_left h2 {
    font-size: 14px !important;
    letter-spacing: 0px;
  }
  .dfl_finr_coll_bracelet_img {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
  }
  .dfl_fine_jwelery_banner {
    height: 300px;
  }
  .banner_cotntent h1 span {
    font-size: 30px;
    letter-spacing: 16px;
    line-height: 25px;
  }
  .content_left h2 {
    font-size: 18px;
    letter-spacing: 4px;
  }
  .banner_cotntent p {
    padding: 0 0;
    font-size: 15px;
  }
  .banner_cotntent a {
    font-size: 14px;
  }
  .dfl_fine_collection_Section {
    padding: 30px 0 0;
  }
  .content_left p {
    font-size: 13px;
    margin: 10px 0 15px;
    min-height: 40px;
  }
  .content_left a {
    font-size: 12px;
    letter-spacing: 1px;
  }
  .content_right a {
    letter-spacing: 2px;
    font-size: 12px;
  }
  .content_right p {
    margin: 10px 0 15px;
    min-height: 40px;
    font-size: 13px;
  }
  .content_right h2 {
    letter-spacing: 0px;
    font-size: 14px;
  }
  .pendants_bg,
  .guid_bg,
  .necklaces_bg,
  .earring_bg,
  .ring_bg,
  .bracelets_bg {
    height: 280px;
  }
  .dfl_fine_collection_Section img {
    width: 150px;
    object-fit: contain;
  }
  .dfld_img_shap_box_inner.dfl_eng_ring_img img {
    width: 160px;
    height: 160px;
  }
  .both-pr {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 0;
  }
  .eng-inner-banner-text {
    padding: 10px;
    padding-bottom: 10px;
  }
  .btn-wrapper {
    padding-top: 5px;
  }
  .rose_bg,
  .white_bg,
  .platinum_bg,
  .yellow_bg {
    height: 215px;
  }
  .rose_bg h2,
  .white_bg h2,
  .platinum_bg h2,
  .yellow_bg h2 {
    font-size: 20px;
    letter-spacing: 6px !important;
  }
  .rose_bg p,
  .white_bg p,
  .platinum_bg p,
  .yellow_bg p {
    letter-spacing: 3px !important;
    font-size: 12px;
  }
  .dfl_eng_metal_type_section {
    padding: 20px 0 0px;
  }
  .dfld_loose_diamond_Section {
    height: 440px;
  }
  .dfl_diamond_gif {
    width: 130px;
    height: 130px;
    top: 7%;
  }
  .dfld_loose_text_box h2 {
    font-size: 25px;
  }
  .dfld_loose_text_box h1 {
    font-size: 35px;
    margin-top: 8px;
    margin-bottom: 4px;
  }
  .dfld_loose_text_box h3 {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 24px;
  }
  .dfld_loose_text_box p {
    margin-bottom: 5px;
    line-height: 22px;
  }
  .dfld_img_shap_box_inner img {
    width: 60px;
    height: 60px;
    margin: 0 auto 10px;
  }
  .dfld_img_shap_box_inner p {
    font-size: 14px;
    padding: 5px 8px;
  }
  .dfld_img_shap_box_inner {
    padding: 10px;
  }
  .dfld_explore_diamond_shap_slider .slick-prev,
  .dfld_explore_diamond_shap_slider .slick-next {
    width: 30px;
    height: 30px;
  }
  .dfld_explore_diamond_shap_slider .slick-prev {
    left: 0;
    z-index: 9;
  }
  .dfld_explore_diamond_shap_slider .slick-next {
    right: 0;
    z-index: 9;
  }
  .dfld_own_engagment_ring_section h2 {
    font-size: 25px;
  }
  .title-box4 {
    margin-bottom: 5px;
  }
  .dfld_own_engagment_ring_section .both-pr {
    font-size: 15px;
    line-height: 22px;
    color: #1b1b1b;
    text-align: center;
    margin-bottom: 10px;
  }
  .btn-engage {
    font-size: 12px;
    width: 160px !important;
  }
  .btn-diamond {
    font-size: 12px;
    width: 170px !important;
  }
  .both-box-hd {
    font-size: 25px;
    padding-bottom: 10px;
  }
  .dfld_diamond_Education_Section {
    padding: 30px 0 0px;
  }
  .d-secv1,
  .d-secv2 {
    height: 300px;
  }

  .def_wedding_text_box.banner_cotntent h1 {
    font-size: 16px !important;
    line-height: 24px;
  }
  .def_wedding_text_box.banner_cotntent p {
    font-size: 13px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .dfl_wedding_banner_section .banner_cotntent a {
    font-size: 16px;
    margin: 5px auto;
    line-height: 48px;
    height: 48px;
  }
  .dfl_wedding_static_page_section .dfl_ring_style_slider .dfld_img_shap_box_inner img {
    width: 120px;
    height: 120px;
  }
  .dfl_wedding_static_page_section .dfl_ring_style_slider .dfld_img_shap_box_inner h4 {
    font-size: 14px;
  }
  .dfl_bgimg_ring_style {
    background-image: unset;
    background-color: #fbf6f2;
    height: 380px;
  }
  .dfl_wedding_eternity_ring_section {
    height: 310px;
    padding: 10px 10px;
    background-position: 21% 100%;
  }
  .eternity_ring_content h2 {
    font-size: 18px;
    letter-spacing: 3px;
    margin-bottom: 0;
    padding: 10px 0;
  }
  .eternity_ring_content p {
    padding: 0 10px;
    font-size: 13px;
    margin-bottom: 5px;
  }
  .eternity_ring_content a {
    margin: 0 auto;
    font-size: 16px;
    line-height: 48px;
    height: 48px;
  }
  .metal_content_right {
    padding-left: 3px;
    margin-bottom: 5px;
  }
  .metal_content_left {
    padding-right: 3px;
    margin-bottom: 5px;
  }
  .dfl_buttons_text_box_outer {
    top: 25%;
    right: 0;
    width: 370px;
  }
  .dfl_earth_lab_button_section .btn-engage {
    font-size: 12px;
    width: 100% !important;
    height: 48px;
    padding: 0 0 !important;
    margin-left: 0;
    margin-right: 20px;
    line-height: 48px;
  }
  .btn-wrapper .btn-engage {
    font-size: 12px;
    height: 38px;
    line-height: 38px;
    width: 100% !important;
  }
  .title32 {
    font-size: 28px;
  }

  .dfl_earth_lab_button_section .btn-engage.btn_dimnd {
    font-size: 11px;
    width: 100% !important;
    height: 42px;
    padding: 0 10px !important;
    margin-left: 0;
    margin-right: 10px;
  }
  .dfld_loose_text_box .btn-wrapper .btn-engage {
    font-size: 16px;
    height: 48px;
    line-height: 48px;
  }
}

@media (min-width: 810px) and (max-width: 819px) {
  .rose_bg,
  .white_bg,
  .platinum_bg,
  .yellow_bg {
    height: 237px;
  }
}
@media (min-width: 820px) and (max-width: 833px) {
  .rose_bg,
  .white_bg,
  .platinum_bg,
  .yellow_bg {
    height: 237px !important;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .d-secv1,
  .d-secv2 {
    height: 300px !important;
  }
  .dfl_earth_lab_button_section {
    height: 360px !important;
  }
  .rose_bg,
  .white_bg,
  .platinum_bg,
  .yellow_bg {
    height: 302px !important;
  }
  .dfl_buttons_text_box_outer {
    top: 25%;
    right: 14% !important;
    width: 390px;
  }
}
@media (min-width: 767px) and (max-width: 809px) {
  .rose_bg,
  .white_bg,
  .platinum_bg,
  .yellow_bg {
    height: 224px !important;
  }
}
@media (min-width: 834px) and (max-width: 991px) {
  .rose_bg,
  .white_bg,
  .platinum_bg,
  .yellow_bg {
    height: 244px !important;
  }
}
@media (min-width: 1150px) and (max-width: 1350px) {
  .dfl_earth_lab_button_section {
    height: 450px !important;
  }
  .dfl_wedding_banner_section {
    height: 420px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1440px) {
  .d-secv1,
  .d-secv2 {
    height: 280px;
  }
  .dfl_wedding_banner_section {
    height: 470px;
  }
  .dfl_buttons_text_box_outer p {
    font-size: 16px;
    width: 93%;
  }
  .dfl_buttons_text_box_outer {
    width: 570px;
  }
  .dfl_earth_lab_button_section {
    height: 500px;
  }

  .rose_bg h2,
  .white_bg h2,
  .platinum_bg h2,
  .yellow_bg h2 {
    font-size: 30px;
  }
  .dfld_loose_text_box h2 {
    font-size: 22px;
  }
  .dfld_loose_text_box h1 {
    font-size: 40px;
    margin-top: 12px;
    margin-bottom: 10px;
  }
  .dfld_loose_text_box h3 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
  }
  .dfld_loose_text_box p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
    padding: 0 20px;
  }
  .eng-btn {
    padding-top: 15px;
  }
  .dfld_loose_diamond_Section {
    padding: 40px 0;
    display: flex;
    vertical-align: middle;
    align-items: center;
  }
  .dfld_loose_diamond_Section {
    height: 450px;
  }
  .dfld_shap_slider {
    margin-top: 15px;
  }
  .dfld_img_shap_box_inner img {
    width: 80px;
    height: 80px;
    margin: 0 auto 15px;
  }
  .both-box-hd {
    font-size: 30px;
  }
  .dfld_diamond_Education_Section {
    padding: 40px 0 30px;
  }

  .dfld_img_shap_box_inner p {
    font-size: 14px;
  }
  .dfld_own_engagment_ring_section .both-pr {
    font-size: 14px;
  }
  .eng-box3 h3 {
    font-family: 'Nunito' 'Sans' !important;
  }
  /* fine jewelery */
  .dfl_fine_jwelery_banner {
    height: 392px;
  }
  .banner_cotntent h1 {
    font-size: 18px;
    margin-bottom: 5px;
    line-height: 30px;
  }
  .banner_cotntent h1 span {
    font-size: 40px;
    line-height: 45px;
  }
  .banner_cotntent p {
    font-size: 13px;
  }

  .content_left h2 {
    font-size: 20px;
  }
  .content_left p {
    font-size: 12px;
    margin: 15px 0 25px;
    height: 20px;
  }
  .content_left a {
    font-size: 14px;
  }
  .content_right h2 {
    font-size: 20px;
  }
  .content_right p {
    font-size: 12px;
    margin: 15px 0 25px;
    height: 20px;
  }

  .dfl_ring_style_slider .dfld_img_shap_box_inner img {
    width: 120px;
    height: 120px;
  }
  .dfl_wedding_static_page_section .dfld_explore_diamond_shap_slider .slick-prev,
  .dfl_wedding_static_page_section .dfld_explore_diamond_shap_slider .slick-next {
    width: 30px;
    height: 30px;
    opacity: 0.5;
  }
  .dfl_wedding_static_page_section .dfl_ring_style_slider .dfld_img_shap_box_inner h4 {
    font-size: 12px;
  }
  .dfl_wedding_static_page_section .dfl_ring_style_slider .dfld_img_shap_box_inner p {
    font-size: 10px;
    padding: 0px 0px;
  }
  .eternity_ring_content h2 {
    font-size: 28px;
    margin-bottom: 0;
    padding: 15px 0;
  }
  .eternity_ring_content p {
    font-size: 13px;
  }
  .dfl_wedding_eternity_ring_section {
    height: 330px;
    padding: 30px 15px;
  }
  .dfl_bgimg_ring_style {
    height: 440px;
  }
}
@media (min-width: 1441px) and (max-width: 1600px) {
  .dfl_wedding_banner_section {
    height: 530px !important;
  }
}
@media (min-width: 1100px) and (max-width: 1199px) {
  .dfl_earth_lab_button_section {
    height: 390px;
  }
  .dfl_buttons_text_box_outer {
    width: unset;
  }
}

@media (min-width: 1441px) and (max-width: 1900px) {
  .dfl_earth_lab_button_section {
    height: 550px;
  }
  .dfl_e_engament_ring_section {
    height: 550px;
  }
}
@media (min-width: 1700px) and (max-width: 1750px) {
  .dfl_e_engament_ring_section {
    height: 570px !important;
  }
}

@media (max-width: 767px) {
  .dfl_el_deskmtop_img {
    display: none;
  }
  .dfl_e_engament_ring_section {
    background-image: unset;
    height: unset;
  }
  .dfl_eng_box {
    display: block;
  }
  .dfl_wedding_banner_section {
    background-image: unset;
    height: unset;
  }
}
@media (min-width: 768px) {
  .dfl_el_mobile_img {
    display: none;
  }
  .dfl_wed_banner_d_none_desktop {
    display: none;
  }
  .dfl_eng_ribg_d_none_desktop {
    display: none;
  }
}
